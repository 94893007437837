import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'landing-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  question1 = 'Eu posso propor um valor que cabe no meu bolso?';
  question2 = 'Quais as formas de pagamento?';
  question3 = 'Quais são as etapas para quitar minha dívida?';
  question4 = 'Simulei minha proposta. E agora?';
  question5 = 'Como tenho acesso aos meus boletos?';
  question6 = 'Tive problemas no meu boleto. O que posso fazer?';
  question7 = 'Quanto tempo para confirmar o pagamento do boleto?';
  question8 = 'Em quanto tempo meu nome fica limpo?';

  constructor() { }

  ngOnInit(): void {
  }

}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '../environments/environment';
import { AuthGuard } from './core/guards/auth.guard';
import { LandingComponent } from './pages/landing/landing.component';

const routes: Routes = [
  {
    path: '',
    component: LandingComponent,
  },
  {
    path: 'web/dividas',
    redirectTo: 'web',
  },
  {
    path: 'web',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    loadChildren: () => import('../app/v2/negotiation-portal/negotiation-portal.module')
      .then(m => m.NegotiationPortalModule)
  },
  ...environment.production ? [] : [{
    path: 'sandbox',
    loadChildren: () => import('../app/sandbox/sandbox.module').then(m => m.SandboxModule)
  }],
  {
    path: '**',
    redirectTo: 'web'
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
import { ColorPaletesType, colors, opacities, spacings } from "../../tokens";
import { getColorWithOpacity } from "../../utils/color.util";

export type LoadingContainerVariantType = 'white' | 'orange' | 'personalBlue' | 'businessBlue';

export const getBackgroundColor = (backgroundColor?: string) => {
  const color = backgroundColor || colors.neutralWhite;
  return getColorWithOpacity(color, opacities.intense);
};

export const getLoadingBackgroundColor = (variant?: string, loadingBackgroundColor?: ColorPaletesType) => {
  if (loadingBackgroundColor) return colors[loadingBackgroundColor];

  switch (variant) {
    case 'white':
      return colors.neutralGray100;
    case 'orange':
      return colors.primaryMain;
    case 'personalBlue':
      return colors.secondary600;
    case 'businessBlue':
      return colors.tertiary400
    default:
      return colors.neutralWhite;
  }
};

export const getContainerStyle = (loadingText?: string): any => {
  if (!loadingText) {
    return {
      'justify-content': 'center',
      'align-items': 'center',
    };
  }
  return {
    'justify-content': 'flex-end',
    'align-items': 'flex-start',
  };
};

export const getLoadingContainerMarginStyle = (loadingText?: string): any => {
  if (!loadingText) return {};
  return {
    'margin-top': spacings.sLarge,
    'margin-left': spacings.sLarge,
    'margin-bottom': spacings.lLarge,
  };
};

export const getTextColor = (variant?: string, loadingTextColor?: ColorPaletesType) : ColorPaletesType => {
  if (loadingTextColor) return loadingTextColor;

  switch (variant) {
    case 'businessBlue':
    case 'personalBlue':
      return 'neutralWhite'
    default:
      return 'neutralGray700'
  }
};

export const getLoadingColor = (variant?: string, loadingColor?: ColorPaletesType) : ColorPaletesType  => {
  if (loadingColor) return loadingColor;
  if(variant == 'white') return 'neutralGray600'
  
  return 'neutralWhite';
};
<div class="container hero bg-hero">
  <div class="hero-content">
    <h2>
      Aqui na Omni sempre tem conversa
    </h2>
    <p>
      Confira nossas condições especiais para você quitar suas pendências.
    </p>

    <a (click)="handleEvent($event)" class="btn-cta-primary">Saiba mais</a>
    <a href="https://wa.me/551130032119?text=Olá!%20Quero%20saber%20mais%20sobre%20como%20negociar%20com%20o%20Omni." class="btn-whatsapp-mob" target="_blank">
      <img src="/assets/images/whatsapp.svg" alt="whatsapp"/>
    </a>
    <a href="https://wa.me/551130032119?text=Olá!%20Quero%20saber%20mais%20sobre%20como%20negociar%20com%20o%20Omni." class="btn-whatsapp-web" target="_blank">
      <img src="/assets/images/whatsapp.svg" alt="whatsapp"/>
      <p>Fale conosco</p>
    </a>
  </div>
</div>

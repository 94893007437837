import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'landing-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss']
})
export class StepsComponent implements OnInit {


  @Output() event = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  handleEvent(event: any) {
    this.event.emit(event);
  }
}

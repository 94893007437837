import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FullscreenLoadingComponent } from './fullscreen-loading.component';
import { LoadingModule } from '../loading/loading.module';

@NgModule({
  declarations: [
    FullscreenLoadingComponent
  ],
  imports: [
    CommonModule,
    LoadingModule,
  ],
  exports: [
    FullscreenLoadingComponent
  ]
})
export class FullscreenLoadingModule { }

<div class="acc-item">
  <div class="acc-header" (click)="toggle()">
    <div class="headerTitle">
      {{title}}
    </div>
    <div class="expand">
      <img *ngIf="!showBody" src="assets/images/expand.svg" alt="mostrar">
      <img *ngIf="showBody" src="assets/images/shorten.svg" alt="ocultar">
    </div>
  </div>
  <div *ngIf="showBody">
    <div class="acc-body">
      <ng-content></ng-content>
    </div>
  </div>

</div>

<div class="container gx-0">
  <div [style]="outCircleStyle">
    <div [style]="circleStyle" [@circleAnimation]="circleState1"></div>
  </div>
  <div [style]="outCircleStyle">
    <div [style]="circleStyle" [@circleAnimation]="circleState2"></div>
  </div>
  <div [style]="outCircleStyle">
    <div [style]="circleStyle" [@circleAnimation]="circleState3"></div>
  </div>
</div>
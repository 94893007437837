import { Action, Selector, State, StateContext } from "@ngxs/store";
import { RenegotiationAgreementType, RenegotiationContractApiType, RenegotiationInstallmentType } from "../shared/types/renegotiation.type";
import { Injectable } from "@angular/core";

export class SetContractDetail {
    static readonly type = '[Contract] Set Contract Detail';
    constructor(public payload: RenegotiationContractApiType) {}
}

export class SetAgreementDetail {
    static readonly type = '[Agreement] Set Contract Detail';
    constructor(public payload: RenegotiationAgreementType) {}
}

export class SetCurrentInstallmentDetail {
    static readonly type = '[CurrentInstallment] Set CurrentInstallment Detail';
    constructor(public payload: RenegotiationInstallmentType) {}
}

export interface ContractDetailStateModel {
    currentInstallment: RenegotiationInstallmentType;
    contract: RenegotiationContractApiType;
    agreement: RenegotiationAgreementType;
}

@State<ContractDetailStateModel>({
    name: 'contractDetailState'
})
@Injectable()
export class ContractDetailState{

    @Selector()
    static getContract(state: ContractDetailStateModel) {
      return state.contract;
    }
  
    @Selector()
    static getAgreement(state: ContractDetailStateModel) {
      return state.agreement;
    }

    @Selector()
    static getCurrentInstallment(state: ContractDetailStateModel) {
      return state.currentInstallment;
    }
  
    @Action(SetContractDetail)
    setContractDetail(ctx: StateContext<ContractDetailStateModel>, action: SetContractDetail) {
      const state = ctx.getState();
      ctx.setState({
        ...state,
        contract: action.payload
      });
    }
  
    @Action(SetAgreementDetail)
    setAgreementDetail(ctx: StateContext<ContractDetailStateModel>, action: SetAgreementDetail) {
      const state = ctx.getState();
      ctx.setState({
        ...state,
        agreement: action.payload
      });
    }

    @Action(SetCurrentInstallmentDetail)
    setCurrentInstallmentDetail(ctx: StateContext<ContractDetailStateModel>, action: SetCurrentInstallmentDetail) {
      const state = ctx.getState();
      ctx.setState({
        ...state,
        currentInstallment: action.payload
      });
    }
}

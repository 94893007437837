import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { style, animate, transition, trigger, state } from '@angular/animations';
import { ColorPaletesType } from '../../tokens';
import { getBorderWidth, getCircleBackgroundColor, getCircleBorderColor } from './loading-dot.utils';

@Component({
  selector: 'app-loading-dot',
  templateUrl: './loading-dot.component.html',
  styleUrls: ['./loading-dot.component.scss'],
  animations: [
    trigger('circleAnimation', [
      state('0', style({ opacity: 1 })),
      state('25', style({ opacity: 0.5 })),
      state('50', style({ opacity: 0 })),
      transition('0 => 25', animate('500ms')),
      transition('25 => 50', animate('500ms')),
      transition('50 => 0', animate('500ms')),
    ]),
  ],
})
export class LoadingDotComponent implements OnInit {
  @Input() circleSize: number = 8;
  @Input() color: ColorPaletesType = 'neutralWhite';
  circleState1: number = 50;
  circleState2: number = 25;
  circleState3: number = 0;

  ngOnInit(): void {
    setInterval(() => {
      this.circleState1 = (this.circleState1 + 25) % 75;
      this.circleState2 = (this.circleState2 + 25) % 75;
      this.circleState3 = (this.circleState3 + 25) % 75;
    }, 500);
  }

  get outCircleStyle() {
    return {
      'border-style': 'solid',
      'overflow': 'hidden',
      'margin': '0 4px',
      'border-color': getCircleBorderColor(this.color),
      'width': this.circleSize + 'px',
      'height': this.circleSize + 'px',
      'border-radius': (this.circleSize / 2) + 'px',
      'border-width': getBorderWidth(),
      'box-sizing': 'border-box',
    };
  }
  
  get circleStyle() {
    return {
      'width': '100%',
      'height': '100%',
      'background-color': getCircleBackgroundColor(this.color),
      'border-radius': (this.circleSize / 2) + 'px',
    };
  }
}
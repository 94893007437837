import { Action, Selector, State, StateContext } from "@ngxs/store";
import { NoOfferInstallmentType, RenegotiationContractApiType } from "../shared/types/renegotiation.type";
import { DueDateOptionType } from "../shared/types/due-date-option.type";
import { SaveAgreementsRequestType } from "../shared/services/renegotiation.service";
import { Injectable } from "@angular/core";

export interface NoOfferDataState {
  dueDate?: DueDateOptionType;
  totalValue: number;
  installmentValue: number;
  installmentNumber: number;
}

export class SetNoOfferContract {
  static readonly type = '[Contract] Set Contract No Offer';
  constructor(public payload: RenegotiationContractApiType) { }
}

export class SetInstallmentSelected {
  static readonly type = '[InstallmentSelected] Set InstallmentSelected';
  constructor(public payload: NoOfferDataState) { }
}

export class SetNoOfferDueDates {
  static readonly type = '[DueDates] Set DueDates';
  constructor(public payload: DueDateOptionType[]) { }
}

export interface NoOfferContractStateModel {
  contract: RenegotiationContractApiType;
  installmentSelected: NoOfferDataState;
  dueDates: DueDateOptionType[];
  agreementDataRequest: SaveAgreementsRequestType;
}

export class SetAgreementDataRequest {
  static readonly type = '[AgreementDataRequest] Set AgreementDataRequest';
  constructor(public payload: SaveAgreementsRequestType) { }
}

@State<NoOfferContractStateModel>({
  name: 'noOfferContractState'
})

@Injectable()
export class NoOfferContractState {

  @Selector()
  static getContract(state: NoOfferContractStateModel) {
    return state.contract;
  }

  @Selector()
  static getInstallmentSelected(state: NoOfferContractStateModel) {
    return state.installmentSelected;
  }

  @Selector()
  static getDueDates(state: NoOfferContractStateModel) {
    return state.dueDates;
  }

  @Action(SetNoOfferContract)
  setContractDetail(ctx: StateContext<NoOfferContractStateModel>, action: SetNoOfferContract) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      contract: action.payload
    });
  }

  @Action(SetInstallmentSelected)
  setAgreementDetail(ctx: StateContext<NoOfferContractStateModel>, action: SetInstallmentSelected) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      installmentSelected: action.payload
    });
  }

  @Action(SetNoOfferDueDates)
  setDueDates(ctx: StateContext<NoOfferContractStateModel>, action: SetNoOfferDueDates) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      dueDates: action.payload
    });
  }

  @Selector()
  static getAgreementDataRequest(state: NoOfferContractStateModel) {
    return state.agreementDataRequest;
  }

  @Action(SetAgreementDataRequest)
  setAgreementDataRequest(ctx: StateContext<NoOfferContractStateModel>, action: SetAgreementDataRequest) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      agreementDataRequest: action.payload
    });
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonColorType, ButtonSizeType, ButtonType, ButtonVariantType, getSizeStyle, getStylesToBlock, getTextColor, getVariantStyle } from './button.util';
import { borders, colors } from '../../tokens';
import { IconDataType } from '../../types/icon-data.type';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {

  @Input() type: ButtonType = 'button';
  @Input() disabled?: boolean;
  @Input() variant?: ButtonVariantType = 'containedPrimary';
  @Input() size?: ButtonSizeType = 'medium';
  @Input() loading?: boolean;
  @Input() block?: boolean = true;
  @Input() stopPropagation?: boolean;
  @Input() textColorVariant?: ButtonVariantType;
  @Input() buttonColor?: ButtonColorType;
  @Input() style?: string;
  @Input() containerStyle?: string;
  @Input() paramsCallback?: any;
  @Input() icon?: IconDataType;

  @Output() onPress = new EventEmitter();

  clicked(event: Event) {
    if (this.stopPropagation) event.stopPropagation();
    if (this.disabled || this.loading) return;
    this.onPress.emit(this.paramsCallback);
  }

  get buttonStyles() {
    return `
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: ${borders.radius.medium};
      width: ${this.block ? '100%' : 'auto'};
      ${getVariantStyle(this.variant, this.disabled, this.buttonColor, this.size).trim()}
      ${getSizeStyle(this.size).trim()}
      ${this.style ?? ''}
    `;
  }

  get containerStyles() {
    return `
      ${getStylesToBlock(this.block)}
      ${this.containerStyle ?? ''}
    `;
  }

  get textVariant() {
    return this.size === 'small' ? 'bodySmall' : 'body';
  }

  get textColor() {
    return getTextColor(this.textColorVariant ? this.textColorVariant : this.variant, this.disabled, this.buttonColor, this.size);
  }

  get iconColor(): string {
    return colors[this.textColor];
  }

  get buttonDisabled() {
    return (this.disabled || this.loading) ? true : null;
  }
}

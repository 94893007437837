export type BordersType = {
  radius: {
    none: string;
    quark: string;
    small: string;
    medium: string;
    large: string;
    XL: string;
  };
  width: {
    none: string;
    hairlene: string;
    thin: string;
    thick: string;
    heavy: string;
  };
};

export type BorderRadiusesType = keyof BordersType['radius'];

export type BorderWidthsType = keyof BordersType['width'];

export const borders: BordersType = {
  radius: {
    none: '0px',
    quark: '4px',
    small: '8px',
    medium: '12px',
    large: '20px',
    XL: '24px',
  },
  width: {
    none: '0px',
    hairlene: '0.5px',
    thin: '1px',
    thick: '2px',
    heavy: '4px',
  },
};

import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/portal';
import { Observable } from 'rxjs';
import { CustomDialogConfig } from '../interfaces/custom-dialog-config.interface';
import { AlertModalData } from '../components/alert-modal/interfaces/alert-modal-data.interface';
import { ResponsiveHelper } from 'src/app/v2/shared/helpers/responsive.helper';

@Injectable({
  providedIn: 'root',
})
export class CustomDialogService {
  constructor(private dialog: MatDialog, private responsiveHelper: ResponsiveHelper) { }

  get isMobile() {
    return this.responsiveHelper.isMobile();
  }

  get defaultDialogClass() {
    return 'dialog-bottom-sheet-container';
  }

  get dialogBottomSheetClass() {
    return this.isMobile ? 'dialog-bottom-sheet-mobile' : this.defaultDialogClass;
  }

  openAlertDialog<T>(component: ComponentType<T>, config?: CustomDialogConfig, data?: AlertModalData): Observable<any> {
    const configAlertModal: CustomDialogConfig = {
      width: config?.width || '320px',
      autoFocus: false,
      disableClose: data?.disableClose ?? false,
      panelClass: 'dialog-default-container',
      ...config,
    }
    return this.openDialog(component, configAlertModal, data);
  }

  openAlertBottomSheet<T>(component: ComponentType<T>, config?: CustomDialogConfig, data?: AlertModalData, fullScreen = true): Observable<any> {
    const maxWidth = this.isMobile ? '100%' : config?.maxWidth;
    const configAlertBottomSheet: CustomDialogConfig = {
      maxWidth: maxWidth || '400px',
      ...config,
    }
    return this.openBottomSheet(component, configAlertBottomSheet, data, fullScreen);
  }

  getMaxWidth() {
    return this.responsiveHelper.isDesktop() ? '80vw' : '100%';
  }

  openBottomSheet<T, D = any>(component: ComponentType<T>, config?: CustomDialogConfig, data?: D, fullScreen = true): Observable<any> {
    const width = config?.width || 'auto';
    const configAlertBox: CustomDialogConfig = {
      width: this.responsiveHelper.isDesktop() ? width : '100%',
      maxWidth: this.getMaxWidth(),
      height: this.isMobile && !fullScreen ? 'auto' : '100%',
      autoFocus: false,
      disableClose: config?.disableClose ?? false,
      panelClass: !fullScreen ? this.dialogBottomSheetClass : this.defaultDialogClass,
      position: {
        bottom: '0%',
        right: '0%',
      },
      ...config,
    }
    return this.openDialog(component, configAlertBox, data);
  }

  openDialog<T, D = any>(component: ComponentType<T>, config: CustomDialogConfig, data?: D): Observable<any> {
    const dialogConfig = {
      ...config,
      data,
    } as MatDialogConfig;

    const dialogRef: MatDialogRef<T> = this.dialog.open(component, dialogConfig);

    return dialogRef.afterClosed();
  }

  closeAllModals() {
    this.dialog.closeAll();
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextComponent } from './text.component';

const COMPONENTS = [
  TextComponent,
];

@NgModule({
  declarations: COMPONENTS,
  imports: [
    CommonModule,
  ],
  exports: COMPONENTS
})
export class TextModule { }

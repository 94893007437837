import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from '../../../environments/environment';

const utm_params = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];

(function configUTM() {
  const queryParams = new URLSearchParams(location.search);
  utm_params.forEach(param => {
    if (queryParams.get(param)) {
      sessionStorage.setItem(param, queryParams.get(param) || '');
    }
  });
})();

export const getQueryParamsUTM = () => {
  const result = utm_params.filter(param => !!sessionStorage.getItem(param))
    .map(param => param + '=' + sessionStorage.getItem(param))
    .join('&');
  return result;
};

export const getQueryParamsUTMObject = () => {
  const result: any = {};
  utm_params.filter(param => sessionStorage.getItem(param))
    .forEach(param => { result[param] = sessionStorage.getItem(param); });
  return result;
}

export const authCodeFlowConfig: AuthConfig = {
  // Url of the Identity Provider
  issuer: `${environment.URL_OMNI_AUTH}/auth/realms/cliente-omni`,
  // URL of the SPA to redirect the user to after login
  redirectUri: window.location.origin + '/?' + getQueryParamsUTM(),
  clientId: 'portal-negociacao',
  responseType: 'code',
  scope: 'openid portal-negociacao',
  customQueryParams: {
    tema: 'portal-negociacao',
    ...getQueryParamsUTMObject(),
  },
  showDebugInformation: true,
};


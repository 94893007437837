import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ColorPaletesType, FontWeightType, LineHeightType, TextVariantType } from '../../tokens';
import { getColorStyle, getTextInlineStyle, getVariantStyle, getWeightStyle } from './text.util';

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html'
})
export class TextComponent {

  @Input() color: ColorPaletesType = 'neutralGray600';
  @Input() variant: TextVariantType = 'body';
  @Input() weight?: FontWeightType;
  @Input() lineHeight?: LineHeightType;
  @Input() numberOfLines?: number;
  @Input() textInline?: boolean;

  @Output() onPress = new EventEmitter();

  clicked() {
    this.onPress.emit();
  }

  get textStyle() {
    return `
      ${getVariantStyle(this.variant, this.lineHeight)}
      ${this.weight ? this.weightStyle : ''}
      ${this.numberOfLinesStyle}
      ${this.textInline ? getTextInlineStyle() : ''}
      letter-spacing: -0.02em;
    `;
  }

  get colorStyle() {
    return getColorStyle(this.color);
  }

  get weightStyle() {
    if (!this.weight) { return null; }
    return `font-family: ${getWeightStyle(this.weight)};`;
  }

  get numberOfLinesStyle() {
    if (!this.numberOfLines) { return ''; }
    return `overflow: hidden; display: -webkit-box; -webkit-line-clamp: ${this.numberOfLines}; -webkit-box-orient: vertical;`;
  }
}

import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appRipple]'
})
export class RippleDirective {

  @Input() rippleColor: string = 'rgba(255, 255, 255, 0.5)';
  @Input() rippleDisabled: boolean = false;

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    if (this.rippleDisabled) { return; }

    const ripple = this.renderer.createElement('span');
    const rect = this.el.nativeElement.getBoundingClientRect();
    const size = Math.max(rect.width, rect.height);
    const x = event.clientX - rect.left - size / 2;
    const y = event.clientY - rect.top - size / 2;

    this.renderer.setStyle(ripple, 'position', 'absolute');
    this.renderer.setStyle(ripple, 'border-radius', '50%');
    this.renderer.setStyle(ripple, 'width', `${size}px`);
    this.renderer.setStyle(ripple, 'height', `${size}px`);
    this.renderer.setStyle(ripple, 'left', `${x}px`);
    this.renderer.setStyle(ripple, 'top', `${y}px`);
    this.renderer.setStyle(ripple, 'background', this.rippleColor);
    this.renderer.setStyle(ripple, 'pointer-events', 'none');
    this.renderer.setStyle(ripple, 'animation', 'ripple-effect 0.6s linear');
    this.renderer.appendChild(this.el.nativeElement, ripple);

    this.renderer.listen(ripple, 'animationend', () => {
      this.renderer.removeChild(this.el.nativeElement, ripple);
    });
  }
}

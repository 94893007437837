<ng-container *ngIf="!urlLanding">
  <app-header (event)="handleClick()"></app-header>
  <landing-hero (event)="handleClick()"></landing-hero>
  <landing-steps (event)="handleClick()"></landing-steps>
  <landing-advantages (event)="handleClick()"></landing-advantages>
  <landing-cta (event)="handleClick()"></landing-cta>
  <landing-faq></landing-faq>
  <app-footer></app-footer>
</ng-container>

<app-fullscreen-loading [visible]="loading"></app-fullscreen-loading>
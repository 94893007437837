import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FindIconByProductCategoryService {

  PATH_ASSET_SVG = 'assets/icons_svg';

  isMoto = /^moto$/i;
  isVeiculoleve = /^ve[ií]culo\s*leve/i;
  isVeiculoPesado = /^ve[ií]culo\s*pesado/i;
  isEmprestimoPessoal = /^cr[eEéÉ]dito\s*pessoal/i;
  ischequeEspecial = /^ve[ií]culo\s*leve/i;
  isCartaoCredito = /^cart[ãaAÃ]O\s*DE\s*CR[eéEÉ]dito$/i;

  constructor() { }

  iconChoose(category:string){
    if (this.isVeiculoleve.test(category)) {
      return 'lightVehicle';
    }

    if (this.isVeiculoPesado.test(category)) {
      return 'heavyVehicle';
    }

    if (this.isEmprestimoPessoal.test(category)) {
      return 'personalLoan';
    }

    if (this.ischequeEspecial.test(category)) {
      return 'overdraft';
    }

    if( this.isCartaoCredito.test(category) ) {
      return 'creditCard';
    }
    return 'circleMoney'
  }
}

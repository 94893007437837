import { Injectable } from '@angular/core';
import {
  BorderRadiusesType,
  BorderWidthsType,
  ColorsType,
  FontSizeType,
  FontWeightType,
  LetterSpaceType,
  LineHeightType,
  OpacitiesType,
  SpacingsType,
  borders,
  colors,
  opacities,
  spacings,
  typography,
} from '../tokens';

@Injectable({
  providedIn: 'root'
})

export class StyleService {

  setCSSVariables() {
    this.setDefaultStrokeColor();
    this.setBorders();
    this.setColors();
    this.setOpacities();
    this.setSpacings();
    this.setTypographies();
  }

  setDefaultStrokeColor() {
    document.documentElement.style.setProperty('--default-stroke-color', colors.neutralGray700);
  }

  setBorders() {
    const { radius, width } = borders;

    Object.keys(radius).forEach(key => {
      document.documentElement.style.setProperty(`--border-radius-${key}`, radius[key as BorderRadiusesType]);
    });

    Object.keys(width).forEach(key => {
      document.documentElement.style.setProperty(`--border-width-${key}`, width[key as BorderWidthsType]);
    });
  }

  setOpacities() {
    Object.keys(opacities).forEach(key => {
      document.documentElement.style.setProperty(`--opacity-${key}`, opacities[key as keyof OpacitiesType].toString());
    });
  }

  setColors() {
    Object.keys(colors).forEach(key => {
      document.documentElement.style.setProperty(`--color-${key}`, colors[key as keyof ColorsType]);
    });
  }

  setSpacings() {
    Object.keys(spacings).forEach(key => {
      document.documentElement.style.setProperty(`--space-${key}`, spacings[key as keyof SpacingsType]);
    });
  }

  setTypographies() {
    const { sizes, lineHeights, letterSpaces, weights } = typography;

    Object.keys(sizes).forEach(key => {
      document.documentElement.style.setProperty(`--font-size-${key}`, sizes[key as FontSizeType]);
    });

    Object.keys(lineHeights).forEach(key => {
      document.documentElement.style.setProperty(`--line-height-${key}`, lineHeights[key as LineHeightType].toString());
    });

    Object.keys(letterSpaces).forEach(key => {
      document.documentElement.style.setProperty(`--letter-space-${key}`, letterSpaces[key as LetterSpaceType].toString());
    });

    Object.keys(weights).forEach(key => {
      document.documentElement.style.setProperty(`--font-weight-${key}`, weights[key as FontWeightType]);
    });
  }
}
import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {authCodeFlowConfig} from "../../../../config/auth/authCodeFlowConfig";
import {JwksValidationHandler} from "angular-oauth2-oidc-jwks";

@Component({
  selector: 'landing-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss']
})
export class HeroComponent implements OnInit {

  @Output() event = new EventEmitter();


  constructor() { }

  ngOnInit(): void {
  }

  handleEvent(event: any) {
    this.event.emit(event);
  }

}

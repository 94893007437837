<div class="container">
  <div class="cta">
    <img src="/assets/images/smile.png" alt="">
    <div class="content">
      <p class="pre-title">Portal de negociação</p>
      <h3 class="section-title">
        Consulte sua proposta onde e quando quiser
      </h3>
      <p class="content-text">
        Você pode acessar o portal tanto pelo computador como
        pelo celular, em qualquer horário do dia. Quando suas
        propostas são analisadas, você é comunicado pelo celular.
      </p>
      <a (click)="handleEvent($event)" class="cta-btn">Acessar portal</a>
    </div>
  </div>
</div>
<div>
  <div class="whatsapp-cta">
    <div>
      <h2>Em caso de dúvidas, fale com a gente no WhatsApp</h2>
      <p>
        Nosso atendimento é de segunda à <br/>
        sábado, das 8h às 20h.
      </p>
    </div>
    <a href="https://wa.me/551130032119?text=Olá!%20Quero%20saber%20mais%20sobre%20como%20negociar%20com%20o%20Omni." class="btn-whatsapp" target="_blank"><img src="/assets/images/whatsapp2.svg" alt="whatsapp"/> Chamar no WhatsApp</a>
  </div>
</div>

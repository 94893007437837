<div class="button-container" [style]="containerStyles">
    <button [type]="type" (click)="clicked($event)" [style]="buttonStyles" [attr.disabled]="buttonDisabled" appRipple>
        <app-loading-dot *ngIf="loading; else defaultTpl"></app-loading-dot>
        <ng-template #defaultTpl>
            <app-icon *ngIf="icon"
                class="button-container__icon"
                [category]="icon.category"
                [name]="icon.name"
                [color]="iconColor">
            </app-icon>
            <app-text [variant]="textVariant" [color]="textColor" weight="bold" lineHeight="medium">
                <ng-content></ng-content>
            </app-text>
        </ng-template>
    </button>
</div>
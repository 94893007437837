import {AfterViewInit, Component, HostListener, OnInit} from '@angular/core';
import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf.js';
// @ts-ignore
import * as pdfjsViewer from 'pdfjs-dist/legacy/web/pdf_viewer';
import {PDFViewerOptions} from 'pdfjs-dist/types/web/base_viewer';
import {OAuthService} from "angular-oauth2-oidc";
import {filter} from "rxjs/operators";
import {DataLayerService} from "./core/services/data-layer.service";
import {NavigationEnd} from "@angular/router";
import { CustomRouter as Router } from "./core/utils/custom-router";
import { StyleService } from './style-guide/services/style.service';
import { AuthService } from './core/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  route: string = '';
  title = 'poc-portal-negociacao-miniapp';
  pdfViewer: pdfjsViewer.PDFViewer | null = null;
  pdfDocument: any | null = null;
  pdfHistory: any | null = null;
  pdfLinkService: any | null = null;
  eventBus: any | null = null;
  currentBinaryArray: any | null = null;
  globalDocument: any = document;

  DEFAULT_SCALE_DELTA = 1.1;
  MAX_SCALE = 2.0;
  MIN_SCALE = 0.25;


  constructor(
    private oauthService: OAuthService,
    private _router:Router,
    private _dataLayerService: DataLayerService,
    private styleService: StyleService,
    private authService: AuthService,
  ) {
    this.oauthService.events
      .pipe(filter((e: any) => e.type === 'invalid_nonce_in_state'))
      .subscribe(() => {
        console.log('invalid_nonce_in_state');
        this.oauthService.initImplicitFlow();
      });

    pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.13.216/pdf.worker.min.js';
    let thisObject = this;

    // @ts-ignore
    this.globalDocument['openPopupPdf'] = async (src) => {
      var loadingTask = pdfjsLib.getDocument({data: src});
      thisObject.pdfDocument = await loadingTask.promise;
      thisObject.pdfViewer?.setDocument(thisObject.pdfDocument);
      this.globalDocument['pdfViewer'] = thisObject.pdfViewer;
      this.currentBinaryArray = src;
      const modalState = {
        modal: true,
        desc: 'pdf'
      };
      // @ts-ignore
      history.pushState(modalState, null);
      // @ts-ignore
      history.pushState(modalState, null);
      history.back();
      setTimeout(() => thisObject.pdfViewer?.update(), 200);
    };

    this._router.events().subscribe(event=> { // subscribe to router events
      if (event instanceof NavigationEnd) //if our event is of our interest
      {
        //GTM:ID0-1
        this._dataLayerService.logEvent({
          event: 'page_view',
          page_path: event.url,
          page_title: document.title,
          page_location: window?.location?.href,
        });
      }
    });
  }

  ngOnInit() {
    this.styleService.setCSSVariables();
  }

  @HostListener('window:pageshow', ['$event'])
  onPageShow() {
    setTimeout(() => {
      if (!this.authService.isLoggedOn()) {
        this._router.navigate(['/']);
      }
    }, 1500);
  }

  closePopupPdf() {
    history.back();
  }

  @HostListener('window:popstate', ['$event'])
  dismissModal() {
    if (window.history?.state?.modal) {
      this.globalDocument.getElementById('popupPdf').style['display'] = 'block';
    } else {
      if (this.pdfDocument) {
        this.pdfDocument = null;
        // @ts-ignore
        this.pdfViewer?.setDocument(null);
        this.pdfLinkService.setDocument(null, null);
        if (this.pdfHistory) {
          this.pdfHistory.reset();
        }
      }
      setTimeout(() => { this.globalDocument.getElementById('popupPdf').style['display'] = null; }, 100);
    }
  }

  ngAfterViewInit(): void {
    let thisObject = this;
    const container = this.globalDocument.getElementById("viewerContainer");
    thisObject.eventBus = new pdfjsViewer.EventBus();
    thisObject.pdfLinkService = new pdfjsViewer.PDFLinkService({
      eventBus: thisObject.eventBus,
    });
    let options: PDFViewerOptions = {
      container,
      eventBus: thisObject.eventBus,
      linkService : thisObject.pdfLinkService,
      l10n: pdfjsViewer.NullL10n,
      useOnlyCssZoom: true,
      textLayerMode: 0,
      // @ts-ignore
      renderer: null
    };
    this.pdfViewer = new pdfjsViewer.PDFViewer(options);
    this.pdfLinkService.setViewer(this.pdfViewer);

    this.pdfHistory = new pdfjsViewer.PDFHistory({
      eventBus: thisObject.eventBus,
      linkService : thisObject.pdfLinkService,
    });
    this.pdfLinkService.setHistory(this.pdfHistory);


    thisObject.eventBus.on("pagesinit", () => {
      // We can use pdfViewer now, e.g. let's change default scale.
      thisObject.pdfDocument.currentScaleValue = "auto";
      const page = 1;
      const numPages = thisObject.pdfDocument.numPages;
      this.globalDocument.getElementById("pageNumber").value = "1";
      this.globalDocument.getElementById("pdfZoom").value = "automatico";

      this.globalDocument.getElementById("pageNumber").value = page;
      this.globalDocument.getElementById("previous").disabled = page <= 1;
      this.globalDocument.getElementById("next").disabled = page >= numPages;
    });
    thisObject.eventBus.on(
      "pagechanging",
      // @ts-ignore
      (evt) => {
        const page = evt.pageNumber;
        const numPages = thisObject.pdfDocument.numPages;

        this.globalDocument.getElementById("pageNumber").value = page;
        this.globalDocument.getElementById("previous").disabled = page <= 1;
        this.globalDocument.getElementById("next").disabled = page >= numPages;
      },
      true
    );
  }

  // @ts-ignore
  zoomIn(ticks){
    // @ts-ignore
    let newScale: any = this.pdfViewer.currentScale;
    do {
      newScale = (newScale * this.DEFAULT_SCALE_DELTA).toFixed(2);
      newScale = Math.ceil(newScale * 10) / 10;
      newScale = Math.min(this.MAX_SCALE, newScale);
    } while (--ticks && newScale < this.MAX_SCALE);
    // @ts-ignore
    this.pdfViewer.currentScaleValue = newScale;
    this.globalDocument.getElementById("pdfZoom").value = (newScale * 100).toFixed(0) + "%";
  }
  // @ts-ignore
  zoomOut(ticks){
    // @ts-ignore
    let newScale: any = this.pdfViewer.currentScale;
    do {
      newScale = (newScale / this.DEFAULT_SCALE_DELTA).toFixed(2);
      newScale = Math.floor(newScale * 10) / 10;
      newScale = Math.max(this.MIN_SCALE, newScale);
    } while (--ticks && newScale > this.MIN_SCALE);
    // @ts-ignore
    this.pdfViewer.currentScaleValue = newScale;
    this.globalDocument.getElementById("pdfZoom").value = (newScale * 100).toFixed(0) + "%";
  }
  nextPage(){
    // @ts-ignore
    let pageNumber = this.pdfViewer.currentPageNumber + 1;
    if(pageNumber <= this.pdfDocument.numPages){
      // @ts-ignore
      this.pdfViewer.currentPageNumber = pageNumber;
      this.globalDocument.querySelectorAll(`[data-page-number='${pageNumber}']`)[0].scrollIntoView();
    }
  }
  previousPage(){
    // @ts-ignore
    let pageNumber = this.pdfViewer.currentPageNumber - 1;
    if(pageNumber > 0){
      // @ts-ignore
      this.pdfViewer.currentPageNumber = pageNumber;
      this.globalDocument.querySelectorAll(`[data-page-number='${pageNumber}']`)[0].scrollIntoView();
    }
  }
  download(){
    var blob = new Blob([this.currentBinaryArray], {type: "application/pdf"});
    var link = this.globalDocument.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    var fileName = 'arquivo.pdf';
    link.download = fileName;
    link.click();
  }
}



// oauthService.configure(authCodeFlowConfig);
// oauthService.loadDiscoveryDocumentAndLogin().then(_ => {
//   const claims:any = this.oauthService.getIdentityClaims();
//   console.log(claims['give_name']);
// });

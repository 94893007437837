import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'landing-cta',
  templateUrl: './cta.component.html',
  styleUrls: ['./cta.component.scss']
})
export class CtaComponent implements OnInit {

  @Output() event = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  handleEvent(event: any) {
    this.event.emit(event);
  }
}

<div class="container">
  <div class="faq-wrapper">
    <div class="faq-title">
      <h2>Dúvidas frequentes</h2>
    </div>
    <app-accordion>
      <app-accordion-item [title]="question1">Sim, você irá informar o valor que pode pagar e iremos analisar sua proposta.</app-accordion-item>
      <app-accordion-item [title]="question2">Sua proposta pode ser à vista ou parcelada, o que dependerá das condições atuais do seu contrato. Se o acordo for aprovado será gerado um boleto para que você efetue o pagamento.</app-accordion-item>
      <app-accordion-item [title]="question3">Já fez seu cadastro? Caso não tenha feito você deverá criar um login, de forma rápida, poucas informações e validar seu cadastro por e-mail.<br><br>
        Se já tiver cadastro basta fazer seu login, clicar no contrato com a dívida em aberto, depois em simular proposta.
        Informe o valor que pode pagar, em seguida a forma da proposta, se será à vista ou parcelado, depois a melhor data de vencimento do boleto e clique em enviar a proposta.</app-accordion-item>
      <app-accordion-item [title]="question4">Após confirmar a sua proposta no portal, ela irá para análise, esse processo é rápido e online, após análise você receberá uma comunicação sobre o status da sua proposta.</app-accordion-item>
      <app-accordion-item [title]="question5">Para acessar seu boleto, assim que a proposta for aprovada, acesse negocie.omni.com.br</app-accordion-item>
      <app-accordion-item [title]="question6">Caso tenha algum problema no momento de pagar o boleto entre em contato conosco:<br><br>
        4004 3500 (Capitais e Regiões Metropolitanas)<br><br>
        0800 701 3500 (Demais Regiões)</app-accordion-item>
      <app-accordion-item [title]="question7">O sistema pode demorar até 3 dias para confirmar o pagamento do boleto.</app-accordion-item>
      <app-accordion-item [title]="question8">Após o sistema reconhecer o pagamento do boleto, normalmente em até 5 dias úteis seu nome é retirado dos órgãos de restrição ao crédito (Serasa e/ou SPC).</app-accordion-item>
    </app-accordion>
  </div>
</div>

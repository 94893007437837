export class TextHelper {
  static capitalizeFirstLetter(text: string) {
    const result = text.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
      return a.toUpperCase();
    });
    return result;
  }

  static capitalizeText = (str: string) => str.toLowerCase().replace(/(?:^|\s)\S/g, val => val.toUpperCase());

  static textPxToNumber(text: string): number {
    return Number(text.replace('px', ''))
  }

  static encodeFileName(url: string) {
    return url.replace(/[^a-z\d-_.]/gi, '_');
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrlFormatPipe } from './convert-percentage/brl-format.pipe';
import { DoubleDigitPipe } from './double-digit/double-digit.pipe';

const PIPES = [
  BrlFormatPipe,
  DoubleDigitPipe,
];

@NgModule({
  declarations: [PIPES],
  imports: [
    CommonModule,
  ],
  exports: [PIPES]
})
export class PipesModule { }

<div class="alert-modal" tabindex="-1">
  <div class="alert-modal__title">
    <app-text mat-dialog-title variant="headingSmall" color="neutralGray700">{{ data.title }}</app-text>
  </div>
    <div mat-dialog-content class="alert-modal__description">
      <app-text>{{ data.description }}</app-text>

      <div class="alert-modal__description--extra" *ngIf="data.extraText">
        <app-text weight="bold">{{ data.extraText }}</app-text>
      </div>
    </div>
    <div mat-dialog-actions>
      <app-button
        *ngIf="data.primaryButtonName"
        size="large"
        (onPress)="onPressPrimary()"
        [loading]="data.loading">
        {{ data.primaryButtonName }}
      </app-button>

      <app-button
        *ngIf="data.secondaryButtonName"
        variant="text"
        [containerStyle]="secondaryButtonContainerStyle"
        (onPress)="onPressSecondary()"
        [disabled]="data.loading">
        {{ data.secondaryButtonName }}
      </app-button>
    </div>    
</div>
import {Injectable} from "@angular/core";
import {NavigationBehaviorOptions, NavigationExtras, Router, UrlTree} from "@angular/router";
import {getQueryParamsUTM, getQueryParamsUTMObject} from "../../config/auth/authCodeFlowConfig";

@Injectable({
  providedIn: 'root'
})
export class CustomRouter {

  constructor(private router: Router) {
  }

  navigateByUrl(url: string | UrlTree, extras?: NavigationBehaviorOptions): Promise<boolean> {
    let queryParamsUTM = getQueryParamsUTM();
    queryParamsUTM = queryParamsUTM ? `&${queryParamsUTM}` : '';
    if (typeof url === 'string') {
      if (url.indexOf('?') === -1) {
        url = `${url}?${queryParamsUTM}`;
      } else {
        url = `${url}${queryParamsUTM}`;
      }
    }

    return this.router.navigateByUrl(url, extras);
  }
  navigate(commands: any[], extras?: NavigationExtras): Promise<boolean> {
    const queryParamsUTMObject = getQueryParamsUTMObject();
    if (extras) {
      if (extras.queryParams) {
        extras.queryParams = {
          ...extras.queryParams,
          ...queryParamsUTMObject
        }
      } else {
        extras.queryParams = queryParamsUTMObject;
      }
    } else {
      extras = {
        queryParams: queryParamsUTMObject
      }
    }
    return this.router.navigate(commands, extras);
  }
  events() {
    return this.router.events;
  }
}

export type SpacingsType = {
  sZero: string;
  sPhoton: string;
  sQuark: string;
  sNano: string;
  sXXS: string;
  sXS: string;
  sSmall: string;
  sMedium: string;
  sLarge: string;
  sXL: string;
  sXXL: string;
  lXS: string;
  lSmall: string;
  lMedium: string;
  lLarge: string;
  lXL: string;
  lHuge: string;
  lGiant: string;
};

export type SpacingSizesType = keyof SpacingsType;

export const spacings: SpacingsType = {
  sZero: '0px',
  sPhoton: '2px',
  sQuark: '4px',
  sNano: '8px',
  sXXS: '12px',
  sXS: '16px',
  sSmall: '20px',
  sMedium: '24px',
  sLarge: '28px',
  sXL: '32px',
  sXXL: '40px',
  lXS: '48px',
  lSmall: '56px',
  lMedium: '64px',
  lLarge: '80px',
  lXL: '120px',
  lHuge: '160px',
  lGiant: '200px',
};

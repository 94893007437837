export const typography = {
    sizes: {
        huge: '48px',
        XXXL: '40px',
        XXL: '33px',
        XL: '27px',
        large: '23px',
        medium: '19px',
        small: '16px',
        XS: '14px',
        XXS: '13px',
        XXXS: '11px',
    },
    weights: {
        bold: 'AvertaCyrillic-Bold',
        semiBold: 'AvertaCyrillic-SemiBold',
        regular: 'AvertaCyrillic-Regular',
    },
    lineHeights: {
        /**
         * 100%
         */
        small: 1,
        /**
         * 120%
         */
        medium: 1.2,
        /**
         * 140%
         */
        large: 1.4,
    },
    letterSpaces: {
        default: -0.02,
    },
};

export type TypographyType = typeof typography;
export type FontSizeType = keyof TypographyType['sizes'];
export type LineHeightType = keyof TypographyType['lineHeights'];
export type LetterSpaceType = keyof TypographyType['letterSpaces'];
export type FontWeightType = 'bold' | 'semiBold' | 'regular';

export type TextVariantType =
  // heading
  'headingDisplay' |
  'headingXLarge' |
  'headingLarge' |
  'headingMedium' |
  'headingSmall' |
  // paragraph regular
  'subtitle' |
  'body' |
  'bodySmall' |
  'caption' |
  // small
  'small' |
  'smallCaps' |
  // one line
  'oneLineHuge' |
  'oneLineLarge' |
  'oneLineMedium' |
  'oneLineSmall' |
  'oneLineCaption' |
  'oneLineSemibold' |
  'oneLineSemiboldCaption' |
  'oneLineBoldHuge' |
  'oneLineBoldExtraLarge' |
  'oneLineBoldLarge' |
  'oneLineBoldMedium' |
  'oneLineBoldSmall' |
  'oneLineBoldCaption';

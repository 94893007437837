import { State, Action, StateContext, Selector } from '@ngxs/store';
import { OfferDataType, OfferPlanType } from '../shared/types/offer-data.type';
import { RenegotiationContractApiType } from '../shared/types/renegotiation.type';
import { Injectable } from '@angular/core';

export interface OfferDataState extends OfferDataType {
  selectedOffer?: OfferPlanType;
  dueDateIndex?: number;
  valueSuggest?: number;
}

export class SetContract {
  static readonly type = '[Contract] Set Contract';
  constructor(public payload: RenegotiationContractApiType) {}
}

export class SetPreApprovedOffer {
  static readonly type = '[Contract] Set Pre-Approved Offer';
  constructor(public payload: OfferDataState) {}
}

export interface ContractStateModel {
  contract: RenegotiationContractApiType;
  preApprovedOffer: OfferDataState;
}

@State<ContractStateModel>({
  name: 'contractState',
})
@Injectable()
export class PreApprovedState {

  @Selector()
  static getContract(state: ContractStateModel) {
    return state.contract;
  }

  @Selector()
  static getPreApprovedOffer(state: ContractStateModel) {
    return state.preApprovedOffer;
  }

  @Action(SetContract)
  setContract(ctx: StateContext<ContractStateModel>, action: SetContract) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      contract: action.payload
    });
  }

  @Action(SetPreApprovedOffer)
  setPreApprovedOffer(ctx: StateContext<ContractStateModel>, action: SetPreApprovedOffer) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      preApprovedOffer: action.payload
    });
  }
}

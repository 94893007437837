import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertModalData } from './interfaces/alert-modal-data.interface';
import { spacings } from '../../tokens';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss'],
})
export class AlertModalComponent {

  constructor(
    public dialogRef: MatDialogRef<AlertModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AlertModalData,
  ) { }

  onPressPrimary() {
    this.dialogRef.close({
      primaryPressed: true,
    });
  }

  onPressSecondary() {
    this.dialogRef.close({
      secondaryPressed: true,
    });
  }

  get secondaryButtonContainerStyle() {
    return `
      margin-top: ${spacings.sXXS};
    `;
  }
}

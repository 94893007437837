import { CUSTOM_ELEMENTS_SCHEMA, DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { OAuthModule } from 'angular-oauth2-oidc';

import ptBr from '@angular/common/locales/pt';
import { CurrencyPipe, DatePipe, registerLocaleData } from '@angular/common';
import { FindIconByProductCategoryService } from './share/services/find-icon-by-product-category.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { WebInterceptor } from './core/services/web-interceptor.service';
import { LayoutModule } from '@angular/cdk/layout';
import { LandingModule } from './pages/landing/landing.module';
import { DataLayerService } from "./core/services/data-layer.service";
import { WindowReferenceService } from "./core/services/window-reference.service";
import { StyleService } from './style-guide/services/style.service';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgxMaskModule } from 'ngx-mask';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxsModule } from '@ngxs/store';
import { environment } from 'src/environments/environment';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { PreApprovedState } from './v2/states/pre-approved.state';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';
import { AgreementState } from './v2/states/agreement.state';
import { PipesModule } from './style-guide/pipes/pipes.module';
import { ContractDetailState } from './v2/states/contract-detail.state';
import { NoOfferContractState } from './v2/states/no-offer.state';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AnalyticsState } from './v2/states/analytics.state';
import { SharedState } from './v2/states/shared.state';

registerLocaleData(ptBr);

const STATES = [
  PreApprovedState,
  AgreementState,
  ContractDetailState,
  NoOfferContractState,
  AnalyticsState,
  SharedState,
];

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: ['https://none'],
        sendAccessToken: true,
      },
    }),
    LayoutModule,
    LandingModule,
    PipesModule,
    AngularSvgIconModule.forRoot(),
    NgxMaskModule.forRoot(),
    MatDialogModule,
    NgxsModule.forRoot(STATES, {
      developmentMode: !environment.production
    }),
    NgxsStoragePluginModule.forRoot({
      key: STATES,
      storage: StorageOption.LocalStorage,
    }),
    NgxsLoggerPluginModule.forRoot({
      disabled: environment.production,
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production,
    }),
    NgxsResetPluginModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    CurrencyPipe,
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' },
    FindIconByProductCategoryService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: WebInterceptor,
      multi: true
    },
    DatePipe,
    WindowReferenceService,
    DataLayerService,
    StyleService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }


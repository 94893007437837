import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'landing-advantages',
  templateUrl: './advantages.component.html',
  styleUrls: ['./advantages.component.scss']
})
export class AdvantagesComponent implements OnInit {

  @Output() event = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  handleEvent(event: any) {
    this.event.emit(event);
  }
}

import { State, Action, StateContext, Selector } from '@ngxs/store';
import { RenegotiationAgreementType, RenegotiationInstallmentType } from '../shared/types/renegotiation.type';
import { Injectable } from '@angular/core';

export interface AgreementDataState {
  contractId: string;
  agreement: RenegotiationAgreementType;
  currentInstallment?: RenegotiationInstallmentType;
}

export class SetAgreement {
  static readonly type = '[Agreement] Set Agreement';
  constructor(public payload: AgreementDataState) { }
}

export interface AgreementStateModel {
  agreement: AgreementDataState;
}

@State<AgreementStateModel>({
  name: 'agreementState',
})
@Injectable()
export class AgreementState {

  @Selector()
  static getAgreement(state: AgreementStateModel) {
    return state.agreement;
  }

  @Action(SetAgreement)
  setAgreement(ctx: StateContext<AgreementStateModel>, action: SetAgreement) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      agreement: action.payload
    });
  }
}

import { Component, HostBinding, Input } from '@angular/core';
import { CategoryIconType } from './icon.util';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent {

  @Input() width?: string;
  @Input() height?: string;
  @Input() category: CategoryIconType = 'default';
  @Input() name?: string;
  @HostBinding('style.--local-stroke-color') @Input() color?: string;

  getSizeIcon(size?: string) {
    if (size) {
      return size;
    }

    if (this.category === 'small') {
      return '16';
    }

    return '24';
  }

 get iconStyle() {
    return {
      'width': `${this.getSizeIcon(this.width)}px`,
      'height': `${this.getSizeIcon(this.height)}px`,
    };
  }

  get iconUrl() {
    return `assets/v2/icons/${this.category}/${this.name}.svg`;
  }
  
}

import { ColorPaletesType, LineHeightType, TextVariantType, typography, colors, FontWeightType } from "../../tokens";

const calcLineHeight = (fontSize: string, multiplier: number) => {
    if (!multiplier) {
        return;
    }

    const fontSizeFormatted = parseInt(fontSize.slice(0, -2));

    return `${Math.round(fontSizeFormatted * multiplier)}px`;
}

const getHeadingStyles: any = (lineHeight: number | null) => ({
    headingDisplay: `
        font-family: ${typography.weights.bold};
        font-size: ${typography.sizes.huge};
        line-height: ${calcLineHeight(typography.sizes.huge, lineHeight ?? typography.lineHeights.medium)};
    `,
    headingXLarge: `
        font-family: ${typography.weights.bold};
        font-size: ${typography.sizes.XXXL};
        line-height: ${calcLineHeight(typography.sizes.XXXL, lineHeight ?? typography.lineHeights.medium)};
    `,
    headingLarge: `
        font-family: ${typography.weights.bold};
        font-size: ${typography.sizes.XXL};
        line-height: ${calcLineHeight(typography.sizes.XXL, lineHeight ?? typography.lineHeights.large)};
    `,
    headingMedium: `
        font-family: ${typography.weights.bold};
        font-size: ${typography.sizes.XL};
        line-height: ${calcLineHeight(typography.sizes.XL, lineHeight ?? typography.lineHeights.medium)};
    `,
    headingSmall: `
        font-family: ${typography.weights.bold};
        font-size: ${typography.sizes.large};
        line-height: ${calcLineHeight(typography.sizes.large, lineHeight ?? typography.lineHeights.medium)};
    `,
});

const getSmallStyles = (lineHeight: number | null) => ({
    small: `
        font-family: ${typography.weights.bold};
        font-size: ${typography.sizes.XS};
        line-height: ${calcLineHeight(typography.sizes.XS, lineHeight ?? typography.lineHeights.medium)};
    `,
    smallCaps: `
        font-family: ${typography.weights.bold};
        font-size: ${typography.sizes.XXXS};
        line-height: ${calcLineHeight(typography.sizes.XXXS, lineHeight ?? typography.lineHeights.medium)};
        text-transform: uppercase;
    `,
});

const getOneLineStyles = (lineHeight: number | null) => ({
    oneLineHuge: `
        font-family: ${typography.weights.regular};
        font-size: ${typography.sizes.XL};
        line-height: ${calcLineHeight(typography.sizes.XL, lineHeight ?? typography.lineHeights.small)};
    `,
    oneLineLarge: `
        font-family: ${typography.weights.regular};
        font-size: ${typography.sizes.large};
        line-height: ${calcLineHeight(typography.sizes.large, lineHeight ?? typography.lineHeights.small)};
    `,
    oneLineMedium: `
        font-family: ${typography.weights.regular};
        font-size: ${typography.sizes.small};
        line-height: ${calcLineHeight(typography.sizes.small, lineHeight ?? typography.lineHeights.small)};
    `,
    oneLineSmall: `
        font-family: ${typography.weights.regular};
        font-size: ${typography.sizes.XS};
        line-height: ${calcLineHeight(typography.sizes.XS, lineHeight ?? typography.lineHeights.small)};
    `,
    oneLineCaption: `
        font-family: ${typography.weights.regular};
        font-size: ${typography.sizes.XXS};
        line-height: ${calcLineHeight(typography.sizes.XXS, lineHeight ?? typography.lineHeights.small)};
    `,
    oneLineSemibold: `
        font-family: ${typography.weights.semiBold};
        font-size: ${typography.sizes.medium};
        line-height: ${calcLineHeight(typography.sizes.medium, lineHeight ?? typography.lineHeights.small)};
    `,
    oneLineSemiboldCaption: `
        font-family: ${typography.weights.semiBold};
        font-size: ${typography.sizes.XXS};
        line-height: ${calcLineHeight(typography.sizes.XXS, lineHeight ?? typography.lineHeights.small)};
    `,
    oneLineBoldHuge: `
        font-family: ${typography.weights.bold};
        font-size: ${typography.sizes.XL};
        line-height: ${calcLineHeight(typography.sizes.XL, lineHeight ?? typography.lineHeights.small)};
    `,
    oneLineBoldExtraLarge: `
        font-family: ${typography.weights.bold};
        font-size: ${typography.sizes.large};
        line-height: ${calcLineHeight(typography.sizes.large, lineHeight ?? typography.lineHeights.small)};
    `,
    oneLineBoldLarge: `
        font-family: ${typography.weights.bold};
        font-size: ${typography.sizes.medium};
        line-height: ${calcLineHeight(typography.sizes.medium, lineHeight ?? typography.lineHeights.small)};
    `,
    oneLineBoldMedium: `
        font-family: ${typography.weights.bold};
        font-size: ${typography.sizes.small};
        line-height: ${calcLineHeight(typography.sizes.small, lineHeight ?? typography.lineHeights.small)};
    `,
    oneLineBoldSmall: `
        font-family: ${typography.weights.bold};
        font-size: ${typography.sizes.XS};
        line-height: ${calcLineHeight(typography.sizes.XS, lineHeight ?? typography.lineHeights.small)};
    `,
    oneLineBoldCaption: `
        font-family: ${typography.weights.bold};
        font-size: ${typography.sizes.XXS};
        line-height: ${calcLineHeight(typography.sizes.XXS, lineHeight ?? typography.lineHeights.small)};
    `,
});

const getParagraphStyles = (lineHeight: number | null) => ({
    subtitle: `
        font-family: ${typography.weights.regular};
        font-size: ${typography.sizes.medium};
        line-height: ${calcLineHeight(typography.sizes.medium, lineHeight ?? typography.lineHeights.large)};
    `,
    bodySmall: `
        font-family: ${typography.weights.regular};
        font-size: ${typography.sizes.XS};
        line-height: ${calcLineHeight(typography.sizes.XS, lineHeight ?? typography.lineHeights.large)};
    `,
    caption: `
        font-family: ${typography.weights.regular};
        font-size: ${typography.sizes.XXS};
        line-height: ${calcLineHeight(typography.sizes.XXS, lineHeight ?? typography.lineHeights.medium)};
    `,
    body: `
        font-family: ${typography.weights.regular};
        font-size: ${typography.sizes.small};
        line-height: ${calcLineHeight(typography.sizes.small, lineHeight ?? typography.lineHeights.large)};
    `,
});

export const getVariantStyle = (variant: TextVariantType, lineHeight?: LineHeightType): string => {
    const lineHeightValue: number | null = lineHeight ? typography.lineHeights[lineHeight] : null;

    const variantStyles = {
        // heading
        ...getHeadingStyles(lineHeightValue),
        // small
        ...getSmallStyles(lineHeightValue),
        // one line
        ...getOneLineStyles(lineHeightValue),
        // paragraph
        ...getParagraphStyles(lineHeightValue),
    };

    return variantStyles[variant];
}

export const getTextInlineStyle = (): string => {
    return 'display: inline;';
}

export const getColorStyle = (color: ColorPaletesType): string => {
    return colors[color];
}

export function getWeightStyle(weight: FontWeightType) {
    return typography.weights[weight];
}
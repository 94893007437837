import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingDotComponent } from './loading-dot.component';

const COMPONENTS = [LoadingDotComponent];

@NgModule({
  declarations: COMPONENTS,
  imports: [
    CommonModule,
  ],
  exports: COMPONENTS,
})
export class LoadingDotModule { }

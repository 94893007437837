import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'doubleDigit'
})
export class DoubleDigitPipe implements PipeTransform {

  transform(value: number): string {
    if (value !== null && value !== undefined) {
      return String(value).padStart(2, '0');
    }
    return value;
  }
}

<div class="container advantages">
  <div class="advantages-wrapper">
    <img src="/assets/images/handshake.png" alt="" class="handshake desktop">
    <div class="advantages-content">

      <h2>
        Vantagens de negociar suas
        pendências com a Omni
      </h2>
      <img src="/assets/images/handshake.png" alt="handshake" class="handshake mobile">

      <div class="bullet-list">
        <div class="bullet-item">
          <img src="/assets/images/bullet.svg" alt="*">
          <p>
            Organize sua vida financeira;
        </div>
        <div class="bullet-item">
          <img src="/assets/images/bullet.svg" alt="*">
          <p>
            Propostas especiais para você;
          </p>
        </div>
        <div class="bullet-item">
          <img src="/assets/images/bullet.svg" alt="*">
          <p>
            Regularize qualquer valor em poucos dias;
          </p>
        </div>
        <div class="bullet-item">
          <img src="/assets/images/bullet.svg" alt="*">
          <p>
            Ambiente 100% seguro e digital.
        </div>
      </div>

      <a (click)="handleEvent($event)" class="btn-cta">Fazer uma proposta</a>
    </div>
  </div>

</div>

import { Component, Input } from '@angular/core';
import { spacings, borders, ColorPaletesType } from '../../tokens';
import { getLoadingBackgroundColor, getLoadingColor, getTextColor } from './loading.utils';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
})
export class LoadingComponent {
  @Input() circleSize: number = 8;
  @Input() backgroundColor?: ColorPaletesType;
  @Input() color?: ColorPaletesType;
  @Input() textColor?: ColorPaletesType;
  @Input() variant: string = 'white';
  @Input() text: string = '';
  @Input() visible: boolean = false;
  @Input() style: any;

  get containerStyles() {
    return {
      'display': 'flex',
      'flexDirection': 'column',
      'marginBottom': '40px',
      ...this.style,
    }
  }

  get textStyles() {
    return `
      marginBottom: ${spacings.sLarge};
      width: min-content;
    `;
  }

  get loadingContainerStyle() {
    return {
        'backgroundColor': getLoadingBackgroundColor(this.variant, this.backgroundColor),
        'padding': spacings.sSmall,
        'borderRadius': borders.radius.medium,
        'display': 'flex',
        'justify-content': 'center',
        'width': 'fit-content',
      }
  }

  get getTextColor(): ColorPaletesType {
    return getTextColor(this.variant, this.textColor);
  }

  get loadingDotColor(): ColorPaletesType{
    return getLoadingColor(this.variant, this.color);
  }
}
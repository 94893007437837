<div class="container">
  <div class="steps">
    <p class="pre-title">Passo a passo</p>
    <h2 class="section-title">Veja como é fácil negociar com a Omni</h2>
    <div class="all-steps">
      <div class="step">
        <div class="step-header">
          <img src="/assets/images/step1.svg" alt="Passo 1" class="step-icon">
          <p class="step-name">Passo 1</p>
        </div>
        <p class="step-content">Digite o número do CPF e realize seu cadastro, é rapidinho.</p>
      </div>
      <div class="step">
        <div class="step-header">
          <img src="/assets/images/step2.svg" alt="Passo 2" class="step-icon">
          <p class="step-name">Passo 2</p>
        </div>
        <p class="step-content">Consulte o valor em aberto que precisará ser negociado.</p>
      </div>
      <div class="step">
        <div class="step-header">
          <img src="/assets/images/step3.svg" alt="Passo 3" class="step-icon">
          <p class="step-name">Passo 3</p>
        </div>
        <p class="step-content">Monte sua proposta e escolha a forma de pagamento, à vista ou parcelado.</p>
      </div>
      <div class="step">
        <div class="step-header">
          <img src="/assets/images/step4.svg" alt="Passo 4" class="step-icon">
          <p class="step-name">Passo 4</p>
        </div>
        <p class="step-content">Gere o boleto e efetue o pagamento. Tudo fácil e online!</p>
      </div>
    </div>
    <div class="btn-wrapper"><a (click)="handleEvent($event)" class="btn-cta">Realizar Cadastro</a></div>
  </div>
</div>

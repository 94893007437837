import { Component, OnInit } from '@angular/core';
import { authCodeFlowConfig } from "../../config/auth/authCodeFlowConfig";
import { JwksValidationHandler } from "angular-oauth2-oidc-jwks";
import { OAuthService } from "angular-oauth2-oidc";
import { CustomRouter as Router } from "../../core/utils/custom-router";
import { environment } from "../../../environments/environment";

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  urlLanding: string = '';
  loading = false;

  constructor(
    private oAuthService: OAuthService,
    private router: Router,
  ) {
    this.oAuthService.configure(authCodeFlowConfig);
    this.oAuthService.setStorage(localStorage);
    this.oAuthService.tokenValidationHandler = new JwksValidationHandler();
    this.urlLanding = environment.URL_LANDING;
  }

  async ngOnInit() {
    this.loading = true;
    try {
      await this.oAuthService.loadDiscoveryDocumentAndTryLogin()

      if (this.oAuthService.hasValidIdToken() || this.oAuthService.hasValidAccessToken()) {
        this.router.navigate(['web']);
      } else if (this.urlLanding) {
        window.location.href = this.urlLanding;
      }
    } finally {
      this.loading = false;
    }
  }

  async handleClick() {
    if (this.oAuthService.hasValidIdToken() || this.oAuthService.hasValidAccessToken()) {
      this.router.navigate(['web']);
    } else {
      this.oAuthService.initCodeFlow();
    }
  }

}

import { ColorPaletesType, ColorsType, borders, colors, spacings } from "../../tokens";

export type ButtonType = 'submit' | 'button' | 'reset';

export type ButtonVariantType =
  'containedPrimary' |
  'containedSecondary' |
  'outlinedPrimary' |
  'outlinedSecondary' |
  'text';

export type ButtonSizeType =
  'large' |
  'medium' |
  'small';

export type ButtonColorType =
  'secondary' |
  'tertiary' |
  'neutralGray' |
  'feedbackSuccess' |
  'feedbackError' |
  'feedbackAlert';

const getButtonMainColor = (buttonColor: string) => {
  switch (buttonColor) {
    case 'secondary':
    case 'tertiary':
      return colors[`${buttonColor}Main` as keyof ColorsType];
    case 'feedbackSuccess':
    case 'feedbackError':
    case 'feedbackAlert':
      return colors[`${buttonColor}500` as keyof ColorsType];
    case 'neutralGray':
    default:
      return colors[`${buttonColor}800` as keyof ColorsType];
  }
};

const getButtonColorContainedPrimary = (
  disabled?: boolean,
  buttonColor?: ButtonColorType,
) => {
  if (!buttonColor) return disabled ? colors.primary100 : colors.primaryMain;

  if (disabled) return colors[`${buttonColor}100` as keyof ColorsType];

  return getButtonMainColor(buttonColor);
};

const getButtonColorContainedSecondary = (
  disabled?: boolean,
  buttonColor?: ButtonColorType,
) => {
  if (!buttonColor) return disabled ? colors.primary100 : colors.primary200;

  if (disabled) return colors[`${buttonColor}100` as keyof ColorsType];

  return colors[`${buttonColor}200` as keyof ColorsType];
};

const getButtonColorOutlinedPrimary = (
  disabled?: boolean,
  buttonColor?: ButtonColorType,
) => {
  if (!buttonColor) return disabled ? colors.primary100 : colors.primary600;

  if (disabled) return colors[`${buttonColor}100` as keyof ColorsType];

  switch (buttonColor) {
    case 'feedbackSuccess':
    case 'feedbackError':
    case 'feedbackAlert':
      return colors[`${buttonColor}500` as keyof ColorsType];
    case 'secondary':
    case 'tertiary':
    case 'neutralGray':
    default:
      return colors[`${buttonColor}600` as keyof ColorsType];
  }
};

const getButtonColorOutlinedSecondary = (
  disabled?: boolean,
  buttonColor?: ButtonColorType,
  size?: ButtonSizeType,
) => {
  if (!buttonColor && size === 'small') return disabled ? colors.neutralGray100 : colors.neutralGray200;

  if (!buttonColor) return disabled ? colors.neutralGray100 : colors.neutralGray300;

  if (disabled) return colors[`${buttonColor}100` as keyof ColorsType];

  if (size === 'small') return colors[`${buttonColor}200` as keyof ColorsType];

  return colors[`${buttonColor}300` as keyof ColorsType];
};

const getButtonColorText = (
  disabled?: boolean,
  buttonColor?: ButtonColorType,
) => {
  if (!buttonColor) return disabled ? colors.neutralGray100 : 'transparent';

  if (disabled) return colors[`${buttonColor}100` as keyof ColorsType];

  return 'transparent';
};

export const getVariantStyle = (
  variant?: ButtonVariantType,
  disabled?: boolean,
  buttonColor?: ButtonColorType,
  size?: ButtonSizeType,
) => {
  switch (variant) {
    case 'containedSecondary':
      return `
        background-color: ${getButtonColorContainedSecondary(disabled, buttonColor)};
        border-width: ${borders.width.none};
      `;
    case 'outlinedPrimary':
      return `
        background-color: transparent;
        border-style: solid;
        border-width: ${borders.width.thin};
        border-color: ${getButtonColorOutlinedPrimary(disabled, buttonColor)};
      `;
    case 'outlinedSecondary':
      return `
        background-color: transparent;
        border-style: solid;
        border-width: ${borders.width.thin};
        border-color: ${getButtonColorOutlinedSecondary(disabled, buttonColor, size)};
      `;
    case 'text':
      return `
        background-color: ${getButtonColorText(disabled, buttonColor)};
        border-width: ${borders.width.none};
      `;
    case 'containedPrimary':
    default:
      return `
        background-color: ${getButtonColorContainedPrimary(disabled, buttonColor)};
        border-width: ${borders.width.none};
      `;
  }
};

const getTextColorContainedPrimary = (
  disabled?: boolean,
  buttonColor?: ButtonColorType,
) => {
  if (!buttonColor) return disabled ? 'primary300' : 'neutralWhite';

  if (disabled) return `${buttonColor}300` as ColorPaletesType;

  return 'neutralWhite';
};

const getTextColorPaletes = (buttonColor: ButtonColorType) => {
  switch (buttonColor) {
    case 'secondary':
    case 'tertiary':
      return `${buttonColor}Main` as ColorPaletesType;
    case 'feedbackSuccess':
    case 'feedbackError':
    case 'feedbackAlert':
      return `${buttonColor}500` as ColorPaletesType;
    case 'neutralGray':
    default:
      return `${buttonColor}800` as ColorPaletesType;
  }
};

const getTextColorByButtonColor = (
  disabled?: boolean,
  buttonColor?: ButtonColorType,
) => {
  if (!buttonColor) return disabled ? 'primary300' : 'primaryMain';

  if (disabled) return `${buttonColor}300` as ColorPaletesType;

  return getTextColorPaletes(buttonColor);
};

const getTextColorOutlinedSecondary = (
  disabled?: boolean,
  buttonColor?: ButtonColorType,
  size?: ButtonSizeType,
) => {
  const colorVariantNumber = size === 'small' ? '500' : '600';
  if (!buttonColor) return disabled ? 'neutralGray200' : `neutralGray${colorVariantNumber}` as ColorPaletesType;

  if (disabled) return `${buttonColor}200` as ColorPaletesType;

  const feedbackColorVariantNumber = size === 'small' ? '300' : '400';

  switch (buttonColor) {
    case 'feedbackSuccess':
    case 'feedbackError':
    case 'feedbackAlert':
      return `${buttonColor}${feedbackColorVariantNumber}` as ColorPaletesType;
    case 'secondary':
    case 'tertiary':
    case 'neutralGray':
    default:
      return `${buttonColor}${colorVariantNumber}` as ColorPaletesType;
  }
};

const getTextColorTextVariant = (
  disabled?: boolean,
  buttonColor?: ButtonColorType,
) => {
  if (!buttonColor) return disabled ? 'neutralGray400' : 'neutralGray500';

  if (disabled) return `${buttonColor}400` as ColorPaletesType;

  switch (buttonColor) {
    case 'feedbackSuccess':
    case 'feedbackError':
    case 'feedbackAlert':
      return `${buttonColor}300` as ColorPaletesType;
    case 'secondary':
    case 'tertiary':
    case 'neutralGray':
    default:
      return `${buttonColor}500` as ColorPaletesType;
  }
};

export const getTextColor = (
  variant?: ButtonVariantType,
  disabled?: boolean,
  buttonColor?: ButtonColorType,
  size?: ButtonSizeType,
): ColorPaletesType => {
  switch (variant) {
    case 'containedSecondary':
      return getTextColorByButtonColor(disabled, buttonColor);
    case 'outlinedPrimary':
      return getTextColorByButtonColor(disabled, buttonColor);
    case 'outlinedSecondary':
      return getTextColorOutlinedSecondary(disabled, buttonColor, size);
    case 'text':
      return getTextColorTextVariant(disabled, buttonColor);
    case 'containedPrimary':
    default:
      return getTextColorContainedPrimary(disabled, buttonColor);
  }
};

export const getSizeStyle = ( size?: ButtonSizeType) => {
  switch (size) {
    case 'large':
      return `
        height: 64px;
        min-width: 64px;
        padding: 0 ${spacings.sXL};
      `;
    case 'small':
      return `
        height: 30px;
        min-width: 30px;
        padding: 0 ${spacings.sXS};
      `;
    case 'medium':
    default:
      return `
        height: 48px;
        min-width: 48px;
        padding: 0 ${spacings.sMedium};
      `;
  }
};

export const getStylesToBlock = (block: boolean | undefined) => {
  if (block) return '';

  return `
    flex-direction: row;
  `;
};

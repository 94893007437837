export type ShadowsType = {
  level1: string;
  level2: string;
  level3: string;
  level4: string;
};

export type ShadowLevelsType = keyof ShadowsType;

export const shadows: ShadowsType = {
  level1: 'box-shadow: 0px 1px 1.00px rgba(0, 0, 0, 0.18), 0px 1px 2px rgba(0, 0, 0, 0.1);',
  level2: 'box-shadow: 0px 1px 2.22px rgba(0, 0, 0, 0.22), 0px 1px 6px rgba(0, 0, 0, 0.18);',
  level3: 'box-shadow: 0px 2px 3.84px rgba(0, 0, 0, 0.25), 0px 2px 8px rgba(0, 0, 0, 0.22);',
  level4: 'box-shadow: 0px 3px 4.65px rgba(0, 0, 0, 0.29), 0px 3px 10px rgba(0, 0, 0, 0.24);',
};

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingComponent } from './loading.component';
import { LoadingDotModule } from '../loading-dot/loading-dot.module';
import { TextModule } from '../text/text.module';



@NgModule({
  declarations: [
    LoadingComponent,
  ],
  imports: [
    CommonModule,
    LoadingDotModule,
    TextModule
  ],
  exports: [
    LoadingComponent
  ]
})
export class LoadingModule { }

import { Injectable } from "@angular/core";
import { DeviceDetectorService } from "ngx-device-detector";

@Injectable({
  providedIn: 'root',
})
export class ResponsiveHelper {

  constructor(private deviceService: DeviceDetectorService) { }

  isMobile(): boolean {
    return this.deviceService.isMobile() || this.deviceService.isTablet();
  }

  isDesktop(): boolean {
    return this.deviceService.isDesktop();
  }
}
  